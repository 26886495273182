function onFetchInit(data) {
  let form = {}
  data.forEach((e, i) => {
    form['title' + i] = e.title
    form['homeTitle' + i] = e.homeTitle
    form['contentTitle' + i] = e.contentTitle
    form['image2' + i] = e.image2
    form['image3' + i] = e.image3
    form['detailImage2' + i] = e.detailImage2
    form['detailImage3' + i] = e.detailImage3
  })
  return {
    form,
    typeData: []
  }
}

function onSubmit({ detail, form }) {
  return {
    list: detail.map((e, i) => {
      return {
        ...e,
        title: form['title' + i],
        homeTitle: form['homeTitle' + i],
        contentTitle: form['contentTitle' + i],
        image2: form['image2' + i],
        image3: form['image3' + i],
        detailImage2: form['detailImage2' + i],
        detailImage3: form['detailImage3' + i]
      }
    })
  }
}

export default {
  type: 'cardForm',
  url: '/config/farmConfigTitle/list',
  edit: '/config/farmConfigTitle/updateBatch',
  params: {
    type: '1'
  },
  onFetchInit,
  onSubmit,
  data: (netData = []) => {
    if (!Array.isArray(netData)) return []
    return (netData || []).map((e, i) => {
      return {
        form: [
          {
            name: ['景色', '农家乐', '民宿农舍'][i] + '类标题',
            type: 'input',
            key: 'title' + i
          },
          {
            name: '首页-副标题',
            type: 'input',
            key: 'homeTitle' + i
          },
          {
            name: '内页-副标题',
            type: 'input',
            key: 'contentTitle' + i
          },
          {
            name: 'ICON',
            type: 'iconUpload',
            typeData: [
              {
                key: 'image2' + i,
                desc: '@2x   xhdpi <br/>上传尺寸220*384px'
              },
            ]
          },
          {
            name: '背景',
            type: 'iconUpload',
            typeData: [
              {
                key: 'detailImage2' + i,
                desc: '@2x   xhdpi <br/>上传尺寸220*384px'
              },
            ]
          }
        ]
      }
    })
  }
}
